footer {

  &, * {
    border: 0;
    margin: 0;
    padding: 0;
  }

  position: absolute;
  bottom: 0;

  width: 100%;

  p {
    text-align: center;
  }
}
