header {

  &, * {
    border: 0;
    margin: 0;
    padding: 0;
  }

  & {
    padding: 10px 15px;
  }

  position: relative;

  h1 {
    display: inline-block;
  }

  nav {
    position: absolute;
    bottom: 0;
    right: 0;
    top: 0;

    display: flex;

    a {
      padding: 0 10px;
      text-align: center;

      display: flex;
      align-items: center;
      justify-content: center;

      &.active {
        font-weight: bold;
      }
    }
  }
}
