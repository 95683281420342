body {
  margin: 0;
  overflow: hidden;
}

.carousel-container {
  background: black;
  bottom: 0;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: -1;

  .background {
    min-width: 100%;
    min-height: 100%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);

    &.slide-enter {
      transform: translateX(50%);
    }
    &.slide-enter-active {
      transform: translateX(-50%);
      transition: transform 1000ms ease-in-out;
    }
    &.slide-exit {
      transform: translateX(-50%);
    }
    &.slide-exit-active {
      transform: translateX(-150%);
      transition: transform 1000ms ease-in-out;
    }
  }
}
